import tw, { css, styled } from "twin.macro"

export const Page = styled.div`
  ${tw`flex flex-col justify-between h-screen`}

  ${({ height }) =>
    height &&
    css`
      height: calc(100vh - ${height}px);
    `}
`

export const Map = styled.section`
  ${tw`flex-1 w-full`}

  .gm-style .gm-style-iw-a {
    ${tw`-mt-4`}

    .gm-style-iw-t {
      &::after {
        background: none;
        box-shadow: none;
        transform: none;
        border: solid transparent;
        border-width: 16px;
        ${tw`border-purple-dark mt-0 -ml-4 w-4 h-0 top-0 left-0`}
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      > .gm-style-iw-c {
        ${tw`bg-purple-dark`}
        padding: 0 !important;

        > div {
          overflow: initial !important;
          max-height: none !important;
        }

        > button {
          display: none !important;
        }
      }
    }
  }
`
