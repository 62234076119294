import React, { memo, useCallback, useMemo } from "react"
import { convertDistance, getDistance } from "geolib"

export const withStoresCard = Component =>
  memo(({ name = "StoresCard", currentLocation, handleSelect, open, page, setOpen, store }: any) => {
    const icon = useMemo(
      () => ({
        anchor: new window.google.maps.Point(8, 0),
        scaledSize: new window.google.maps.Size(16, 27),
        url: "data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 27.6a8 8 0 008-8C16 15.18 8 .272 8 .272S0 15.18 0 19.6a8 8 0 008 8z' fill='%23B757AC'/%3E%3C/svg%3E%0A",
      }),
      []
    )

    const distance = useMemo(
      () =>
        currentLocation
          ? Math.round(
              (convertDistance(
                getDistance(
                  { latitude: currentLocation?.lat, longitude: currentLocation?.lng },
                  { latitude: store?.location?.lat, longitude: store?.location?.lng }
                ),
                `km`
              ) +
                Number.EPSILON) *
                100
            ) / 10
          : null,
      [currentLocation, store]
    )

    const handleClick = useCallback(() => {
      if (open) {
        setOpen(null)
      } else {
        handleSelect(store.location)
        setOpen(store.id)
      }
    }, [handleSelect, open, setOpen, store])

    Component.displayName = name
    return useMemo(
      () => <Component {...store} distance={distance} handleClick={handleClick} icon={icon} locales={page} open={open} />,
      [distance, handleClick, icon, page, open, store]
    )
  })
