import tw from "twin.macro"

import { StyledLink } from "../../Styled/Button"
import { Small } from "../../Styled/Text"

export const Card = tw.figure`w-80 px-5.5 py-4 font-sans max-w-full`
export const Links = tw.div`flex flex-col items-start`
export const Link = tw(
  StyledLink
)`flex mb-1 last:mb-0 text-left justify-start items-center font-medium text-purple-dark-pastel hover:text-purple-dark-pastel hover:border-purple-dark-pastel`
export const LinkText = tw.span`block mr-1`
export const Address = tw(Small)`font-normal md:w-3/4 leading-snug max-w-full`
