import React from "react"

import { withStoresHeader } from "./withStoresHeader"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { StoresHeaderForm } from "./Form/StoresHeaderForm"
import { StyledContainer } from "../../Styled/Container"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { H4 } from "../../Styled/Text"
import { Header } from "./styledStoresHeader"

export const StoresHeader = withStoresHeader(
  ({ handleAddress, handleLocation, loading, map, page }): JSX.Element => (
    <Header>
      <StyledContainer width={`xl`}>
        <StyledRow justify={`center`}>
          <StyledColumn width={`md:1/3`}>
            <H4 as={`h1`} align={`center`} withSpacing={`xs`}>
              {page?.title}
            </H4>
            {!loading && <StoresHeaderForm handleAddress={handleAddress} handleLocation={handleLocation} map={map} page={page} />}
          </StyledColumn>
        </StyledRow>
      </StyledContainer>
      <Breadcrumbs page={page} />
    </Header>
  )
)
