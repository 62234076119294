import React from "react"
import { GoogleMap } from "@react-google-maps/api"

import { withStores } from "./withStores"
import { StoresCard } from "./Card/StoresCard"
import { StoresHeader } from "./Header/StoresHeader"
import { Page, Map } from "./styledStores"

export const Stores = withStores(
  ({
    bounds,
    config,
    currentLocation,
    handleAddress,
    handleLoad,
    handleLocation,
    handleSelect,
    handleUnload,
    loading,
    map,
    open,
    page,
    setOpen,
    stores,
  }): JSX.Element => (
    <Page height={bounds?.height}>
      <StoresHeader handleAddress={handleAddress} handleLocation={handleLocation} loading={loading} map={map} page={page} />
      <Map>
        {!loading && (
          <GoogleMap {...config} onLoad={handleLoad} onUnmount={handleUnload}>
            {stores?.map((store: any) => (
              <StoresCard
                key={store?.id}
                currentLocation={currentLocation}
                handleSelect={handleSelect}
                open={open === store?.id}
                page={page}
                setOpen={setOpen}
                store={store}
              />
            ))}
          </GoogleMap>
        )}
      </Map>
    </Page>
  )
)
