import React, { memo, useCallback, useMemo, useState } from "react"
import { useJsApiLoader } from "@react-google-maps/api"

import { useApp } from "../../hooks/useApp"
import { useStores } from "../../hooks/useStores"

export const withStores = Component =>
  memo(({ name = "Stores", page }: any) => {
    const {
      headerBounds,
      config: {
        services: { googleMaps },
      },
    } = useApp()
    const { stores } = useStores()
    const [map, setMap] = useState(null)
    const [open, setOpen] = useState(null)
    const [currentLocation, setCurrentLocation] = useState(null)

    const config = useMemo(
      () => ({
        id: googleMaps?.mapId,
        mapContainerStyle: { width: `100%`, height: `100%` },
        options: {
          disableDefaultUI: true,
          mapId: googleMaps?.mapId,
          minZoom: 4,
          zoomControl: true,
        },
      }),
      [googleMaps]
    )

    const { isLoaded } = useJsApiLoader({
      id: name,
      googleMapsApiKey: googleMaps?.apiKey,
      mapIds: [googleMaps?.mapId],
      libraries: ["places"],
    })

    const handleLoad = useCallback(
      map => {
        const bounds = new window.google.maps.LatLngBounds()

        stores?.map(store => bounds.extend(new window.google.maps.LatLng(store?.location?.lat, store?.location?.lng)))
        map.fitBounds(bounds)
        map.addListener(`click`, () => setOpen(false))
        setMap(map)
      },
      [stores, setMap, setOpen]
    )

    const handleAddress = useCallback(
      bounds => {
        map.fitBounds(bounds)
        setMap(map)
      },
      [map, setMap]
    )

    const handleLocation = useCallback(
      bounds => {
        map.setCenter(bounds)
        map.setZoom(14)
        setCurrentLocation(bounds)
        setMap(map)
      },
      [map, setCurrentLocation, setMap]
    )

    const handleSelect = useCallback(
      bounds => {
        map.setCenter(bounds)
        map.setZoom(14)
        setMap(map)
      },
      [map, setMap]
    )

    const handleUnload = useCallback(() => setMap(null), [setMap])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          bounds={headerBounds}
          config={config}
          currentLocation={currentLocation}
          handleAddress={handleAddress}
          handleLoad={handleLoad}
          handleLocation={handleLocation}
          handleSelect={handleSelect}
          handleUnload={handleUnload}
          loading={!isLoaded}
          map={map}
          open={open}
          page={page}
          setOpen={setOpen}
          stores={stores}
        />
      ),
      [
        headerBounds,
        config,
        currentLocation,
        handleAddress,
        handleLoad,
        handleLocation,
        handleSelect,
        handleUnload,
        isLoaded,
        map,
        open,
        page,
        setOpen,
        stores,
      ]
    )
  })
