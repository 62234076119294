import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { InfoWindow, Marker } from "@react-google-maps/api"

import { withStoresCard } from "./withStoresCard"
import { Icon } from "../../Icon/Icon"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { Overline, H6 } from "../../Styled/Text"
import { Card, Links, Link, LinkText, Address } from "./styledStoresCard"

export const StoresCard = withStoresCard(
  ({ address, description, distance, handleClick, icon, links, locales, location, open, title }): JSX.Element => (
    <>
      {open && (
        <InfoWindow onCloseClick={handleClick} position={location}>
          <Card>
            <StyledRow gutter={`xs`} justify={`between`} withSpacing={`xxs`}>
              <StyledColumn gutter={`xs`} width={`no-shrink`}>
                {description ? <Overline colour={`purple-dark-pastel`}>{description}</Overline> : null}
              </StyledColumn>
              {distance ? (
                <StyledColumn gutter={`xs`} width={`no-shrink`}>
                  <Overline colour={`purple-dark-pastel`} align={`right`}>
                    {locales?.additionalDistance?.replace(`{distance}`, distance)}
                  </Overline>
                </StyledColumn>
              ) : null}
            </StyledRow>
            <H6 colour={`white`} withSpacing={`xxs`}>
              {title}
            </H6>
            <Address colour={`purple-dark-pastel`} large withSpacing={links?.length > 0 ? `xs` : null}>
              {address}
            </Address>
            {links?.length > 0 && (
              <Links>
                {links?.map(({ external, title, url }) => (
                  <Link
                    key={title?.toString()}
                    as={external ? `a` : GatsbyLink}
                    href={external ? url : null}
                    rel={external ? `noopener` : null}
                    target={external ? `_blank` : null}
                    title={title}
                    to={!external ? url : null}
                  >
                    <LinkText>{title}</LinkText>
                    <Icon name={`right`} width={10} />
                  </Link>
                ))}
              </Links>
            )}
          </Card>
        </InfoWindow>
      )}
      <Marker icon={icon} onClick={handleClick} position={location} title={title} />
    </>
  )
)
