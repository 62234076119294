import React from "react"
import { graphql } from "gatsby"

import { Stores as Page } from "../components/Stores/Stores"

export const query = graphql`
  query {
    page: sanityPageStores {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalDistance
      additionalGeolocation
      additionalGeolocationError
      additionalSearch
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
