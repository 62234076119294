import React from "react"

import { withStoresHeaderForm } from "./withStoresHeaderForm"
import { FormInput } from "../../../Form/Input/FormInput"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { Form, Button } from "./styledStoresHeaderForm"

export const StoresHeaderForm = withStoresHeaderForm(
  ({ errors, field, geolocation, handleChange, handleClick, locales, locating, search }): JSX.Element => (
    <Form>
      <StyledColumn width={`grow`}>
        <FormInput errors={errors} inverted onChange={handleChange} placeholder={locales?.additionalSearch} reference={field} value={search} />
      </StyledColumn>
      {geolocation && (
        <Button colour={`white`} disabled={locating} onClick={handleClick} title={locales?.additionalGeolocation}>
          <Icon name={`location`} title={locales?.additionalGeolocation} width={22} />
        </Button>
      )}
    </Form>
  )
)
