import { useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"

export const useStores = () => {
  const { linkResolver } = useRoutes()
  const {
    helpers: { edgeNormaliser },
  } = useApp()

  const { stores: rawStores } = useStaticQuery(graphql`
    query {
      stores: allSanityStore {
        edges {
          node {
            ...GatsbyStoreFragment
          }
        }
      }
    }
  `)

  const storeNormaliser = useCallback(
    (store: any) => ({
      ...store,
      links: [
        ...(store?.phone ? [{ external: true, title: store?.phone, url: `tel:${store?.phone}` }] : []),
        ...(store?.links?.map((link: any) => linkResolver(link)) || []),
      ],
    }),
    [linkResolver]
  )

  const stores = edgeNormaliser(rawStores)
    ?.filter(({ location }) => location?.lat && location?.lng)
    ?.map((store: any) => storeNormaliser(store))

  return {
    storeNormaliser,
    stores,
  }
}
