import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useCore } from "../../../../hooks/useCore"
import { useLocation } from "../../../../hooks/useLocation"

export const withStoresHeaderForm = Component =>
  memo(({ name = "StoresHeaderForm", handleAddress, handleLocation, map, page }: any) => {
    const {
      helpers: { isBrowser },
    } = useCore()
    const { userData } = useLocation()
    const [autoComplete, setAutoComplete] = useState(null)
    const [errors, setErrors] = useState(null)
    const [locating, setLocating] = useState(false)
    const [search, setSearch] = useState(``)
    const field = useRef()

    const geolocation = useMemo(() => isBrowser && navigator?.geolocation, [isBrowser])

    const handleChange = useCallback(({ target: { value } }) => setSearch(value), [setSearch])

    const handleClick = useCallback(async () => {
      setErrors(null)
      setLocating(true)
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          handleLocation({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          })
          setLocating(false)
        },
        async () => {
          const fallbackLocation = await userData()

          if (fallbackLocation?.ipData?.latitude && fallbackLocation?.ipData?.longitude) {
            handleLocation({
              lat: fallbackLocation?.ipData?.latitude,
              lng: fallbackLocation?.ipData?.longitude,
            })
          } else {
            setErrors([{ code: 0, message: page?.additionalGeolocationError }])
          }
          setLocating(false)
        }
      )
    }, [handleLocation, page, setErrors, userData])

    const handleSelect = useCallback(
      places => {
        const addressObject = places.getPlace()
        setErrors(null)
        setSearch(addressObject?.formatted_address || ``)
        handleAddress(addressObject?.geometry?.viewport)
      },
      [handleAddress, setErrors, setSearch]
    )

    useEffect(() => {
      if (!autoComplete && field?.current && map) {
        const places = new window.google.maps.places.Autocomplete(field.current, {
          types: ["(regions)"],
          componentRestrictions: { country: "au" },
        })

        places.setFields(["formatted_address", "geometry"])
        places.addListener("place_changed", () => handleSelect(places))
        setAutoComplete(places)
      }
    }, [autoComplete, field, handleSelect, map, setAutoComplete])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          errors={errors}
          field={field}
          geolocation={geolocation}
          handleChange={handleChange}
          handleClick={handleClick}
          locales={page}
          locating={locating}
          search={search}
        />
      ),
      [errors, field, geolocation, handleChange, handleClick, locating, page, search]
    )
  })
