import React, { memo, useMemo } from "react"

export const withStoresHeader = Component =>
  memo(({ name = "StoresHeader", handleAddress, handleLocation, loading, map, page }: any) => {
    Component.displayName = name
    return useMemo(
      () => <Component handleAddress={handleAddress} handleLocation={handleLocation} loading={loading} map={map} page={page} />,
      [handleAddress, handleLocation, loading, map, page]
    )
  })
